@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Courier New", Courier, monospace;
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
  background-color: #333; /* Slightly lighter dark shade for the header */
}

header .logo {
  font-weight: bold;
  font-size: 24px;
}

nav a {
  margin-left: 20px;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: #ffdd57; /* Lighter yellow on hover */
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px); /* Adjust based on header height */
}

.centered {
  text-align: center;
  font-size: 48px;
}

.centered .tech {
  color: #ffdd57; /* Yellow color for '.tech' */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  header {
    flex-direction: column;
  }

  nav a {
    margin-top: 10px;
  }

  .centered {
    font-size: 32px;
  }
}
